import React, {useState} from 'react';
import { menuItems } from '../../menuItems';
import MenuItems from './MenuItems';
import './Navbar.css';

const Navbar = ({responsive, onExpand, isExpand, handleClickMillon, handleClickPro}) => {
  
  return (
    <>
    <nav>
      {responsive ? (
        <div className="main-icon" onClick={() => onExpand(!isExpand)}>
          <i className={isExpand ? "gg-menu gg-close" : "gg-menu gg-open"}></i>
        </div>
      ) : 
      
      (<ul className="menus">
        
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
              handleClickMillon={handleClickMillon}
              handleClickPro={handleClickPro}
            />
          );
        })}
        <div style={{display:"flex", columnGap:'10px', alignItems:'center', paddingLeft:'10px'}} className='ars-container'>
          <img
            src="/icons/Icono_BanderaARG.svg"
            alt="Bandera de argentina"
            style={{ height: "20px" }}
            className='ars'
          />
          <span style={{ height: "20px", width:'3px', backgroundColor:'rgba(255, 255, 255, 0.6)' }} className='ars'></span>
          <p style={{ color:'rgba(255, 255, 255, 0.6)', fontSize:'20px' }} className='ars'>ARS</p>
        </div>
      </ul>)}
      
    </nav>
    </>
  );
};

export default Navbar;
