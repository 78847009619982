export const menuItems = [
  {
    title: "CASA PACK",
    url: "/",
  },
  {
    title: "CASA PRO",
    url: "/",
  },
  {
    title: "PREGUNTAS FRECUENTES",
    url: "/preguntas-frecuentes",
  },
  {
    title: "CONTACTO",
    url: "/contacto",
  },
];
